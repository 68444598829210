// 设备圈页面modal的全部表单配置
// 全国省市
import provorcity from '@/util/city.json'

const customPhoneVerify = (rule, value, cb) => {
  if (!value) {
    return cb(new Error('请输入手机号码'))
  }
  if (!value.match(/^(?:(?:\+|00)86)?1\d{10}$/)) {
    return cb(new Error('手机号码格式错误'))
  }
  cb()
}
const rules = {
  device_type: [{ required: true, message: '请选择类型', trigger: 'blur' }],
  information: [{ required: true, message: '请输入信息', trigger: 'blur' }],
  city: [{ required: true, message: '请选择地区', trigger: 'blur' }],
  contact_name: [{ required: true, message: '请输入信息', trigger: 'blur' }],
  contact: [{ required: true, message: '请输入信息', trigger: 'blur' }],
  brand_name: [{ required: true, message: '请输入信息', trigger: 'blur' }],
  closing_time: [{ required: true, message: '请输入信息', trigger: 'blur' }],
  opening_hours: [{ required: true, message: '请输入信息', trigger: 'blur' }],
  fact_location: [{ required: true, message: '请输入信息', trigger: 'blur' }],
  equipment_repair: [
    { required: true, message: '请选择类型', trigger: 'blur' },
  ],
  contact: [
    {
      required: true,
      message: '请输入联系方式,长度在5-80个字符',
      trigger: 'blur',
      min: 5,
      max: 80,
    },
  ],
  province_city: [{ required: true, message: '请选择地区', trigger: 'change' }],
}
// 不同导航的不同表单配置对象
export const equipListModalConfig = {
  labelWidth: '82px',
  colLayout: {
    span: 24,
  },
  inputSize: 'small',
  compsStyle: {
    width: '346px',
  },
  rules,
  formItems: [
    {
      required: true,
      field: 'device_type',
      type: 'select',
      label: '设备类型:',
      placeholder: '请选择',
      options: [
        {
          label: '开料机',
          value: 2,
        },
        {
          label: '封边机',
          value: 3,
        },
        {
          label: '侧孔机',
          value: 4,
        },
        {
          label: '五六面钻',
          value: 5,
        },
        {
          label: '其它',
          value: 1,
        },
      ],
    },
    {
      required: true,
      field: 'information',
      type: 'textarea',
      label: '设备简介:',
      placeholder: '请输入',
      otherOptions: {
        maxlength: '300',
        rows: 4,
      },
    },
    {
      required: true,
      field: 'contact',
      type: 'input',
      label: '联系方式:',
      placeholder: '请输入',
    },
    {
      required: true,
      field: 'province_city',
      type: 'cascader',
      label: '所在地区:',
      cascaderOptions: provorcity,
      otherOptions: {
        props: {
          value: 'label',
          label: 'label',
        },
      },
    },
    {
      field: 'picture_url',
      type: 'upload',
      label: '设备照片:',
      otherOptions: {
        uploadHint: '仅支持.jpg .png 格式，大小10M以内',
      },
    },
  ],
}

export const demandInfoModalConfig = {
  labelWidth: '82px',
  inputSize: 'small',
  colLayout: {
    span: 24,
  },
  compsStyle: {
    width: '346px',
  },
  rules,
  formItems: [
    {
      field: 'information',
      type: 'textarea',
      label: '求购信息:',
      placeholder: '请输入',
      otherOptions: {
        maxlength: '300',
        rows: 4,
      },
    },
    {
      field: 'contact',
      type: 'input',
      label: '联系方式:',
      placeholder: '请输入',
    },
    {
      field: 'province_city',
      type: 'cascader',
      label: '所在地区:',
      cascaderOptions: provorcity,
      otherOptions: {
        props: {
          value: 'label',
          label: 'label',
        },
      },
    },
  ],
}

export const supplierListModalConfig = {
  labelWidth: '100px',
  inputSize: 'small',
  colLayout: {
    span: 24,
  },
  compsStyle: {
    width: '346px',
  },
  rules,
  formItems: [
    {
      field: 'information',
      type: 'textarea',
      label: '供应商信息:',
      placeholder: '请输入',
      otherOptions: {
        maxlength: '300',
        rows: 4,
      },
    },
    {
      field: 'contact',
      type: 'input',
      label: '联系方式:',
      placeholder: '请输入',
    },
  ],
}

export const equipmentMaintenanceModalConfig = {
  labelWidth: '110px',
  colLayout: {
    span: 24,
  },
  inputSize: 'small',
  compsStyle: {
    width: '330px',
  },
  rules,
  formItems: [
    {
      field: 'brand_name',
      type: 'textarea',
      label: '商户名称:',
      required: true,
      placeholder: '请输入',
      otherOptions: {
        maxlength: '30',
        rows: 1,
      },
    },
    {
      field: 'equipment_repair',
      type: 'checkbox',
      label: '维修的设备:',
      placeholder: '请输入',
      required: true,
      options: [
        {
          value: '雕刻机',
          label: '雕刻机',
        },
        {
          value: '电子锯',
          label: '电子锯',
        },
        {
          value: '一体机',
          label: '一体机',
        },
        {
          value: '侧孔机',
          label: '侧孔机',
        },
        {
          value: '五/六面钻',
          label: '五/六面钻',
        },
        {
          value: '推台锯',
          label: '推台锯',
        },
        {
          value: '封边机',
          label: '封边机',
        },
        {
          value: '其他设备',
          label: '其他设备',
        },
      ],
    },
    {
      field: 'contact_name',
      type: 'input',
      label: '联系人:',
      required: true,
      placeholder: '请输入',
    },
    {
      field: 'contact',
      type: 'input',
      label: '联系电话:',
      required: true,
      placeholder: '请输入',
    },
    {
      field: 'opening_hours',
      type: 'opening_hours',
      label: '营业时间:',
      required: true,
      placeholder: '请选择',
    },
    {
      field: 'closing_time',
      type: 'closing_time',
      label: '',
      placeholder: '请选择',
      required: true,
    },
    {
      field: 'province_city',
      type: 'cascader',
      label: '所在地区:',
      cascaderOptions: provorcity,
      otherOptions: {
        props: {
          value: 'label',
          label: 'label',
        },
      },
      required: true,
    },
    {
      field: 'fact_location',
      type: 'textarea',
      label: '营业地址:',
      required: true,
      placeholder: '请输入',
      otherOptions: {
        maxlength: '50',
        rows: 1,
      },
    },
    {
      field: 'picture_url',
      type: 'upload',
      label: '上传头像:',
      otherOptions: {
        uploadHint: '只能上传jpg/png文件，且不超过10M',
      },
      required: false,
    },
  ],
}
