<template>
  <div id="equi-comps-page">
    <nav class="nav" v-show="activeNav === 'equipList'">
      <ul>
        <li
          v-for="item in n"
          :key="item.type"
          :class="[
            'nav-item',
            activeChildrenIndex === item.type ? 'active-nav' : '',
          ]"
          @click="handleNav(item.type)"
        >
          {{ item.navName }}
        </li>
      </ul>
    </nav>
    <template v-if="activeNav != 'demandInfo'">
      <div
        :class="[
          'content-warp',
          activeNav !== 'equipList' ? 'content-height' : '',
        ]"
      >
        <template v-if="activeNav != 'equipmentMaintenance'">
          <div
            class="content"
            v-for="item in pageData"
            :key="item.id"
            @click="handleItemClick(item)"
          >
            <img :src="item.picture_url" alt="暂无图片" class="content-img" />
            <div class="content-text">
              <p
                :class="['title', item.id === unfoldItemId ? 'unfoldWrap' : '']"
              >
                {{ item.information }}
              </p>
              <div class="address">
                {{ activeNav === 'supplierList' ? '厂家' : '' }}地区：{{
                  item.province
                }}
                {{ item.city }}
              </div>
              <div class="time">
                <span>联系方式：{{ handleContact(item) }}</span>
                <span v-show="activeNav !== 'supplierList'"
                  >发布时间：{{ item.pub_time }}</span
                >
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="topContent relative">
            <span>
              所在地区：<a-cascader
                :options="locationOptions"
                placeholder="请选择地区"
                @change="handleSearchData"
                :fieldNames="fieldNames"
                v-model="searchData.location"
              />
            </span>
            <span class="ml10">
              设备类型：<a-select
                style="width: 192px"
                @change="handleSearchData"
                placeholder="请选择类型"
                v-model="searchData.type"
                :allow-clear="true"
              >
                <a-select-option
                  :value="item.value"
                  v-for="item in typeOptions"
                  :key="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </span>
            <span class="absolute r0">
              <a-input
                placeholder="请选输入设备名称"
                v-model="searchData.customType"
              />
              <a-button type="primary" @click="handleSearchData">搜索</a-button>
            </span>
          </div>
          <div class="flex mainContent">
            <div v-for="item in pageData" :key="item.id" class="pageItem">
              <p class="fs17 bold">{{ item.brand_name }}</p>
              <div class="flex">
                <img
                  :src="
                    item.picture_url.includes('png')
                      ? item.picture_url.split('?')[0]
                      : item.picture_url
                  "
                  class="pageDataImg mr10"
                />
                <div>
                  <p>
                    维修设备：{{ item.equipment_repair
                    }}{{
                      item.other_equipment ? `、${item.other_equipment}` : ''
                    }}
                  </p>
                  <p>联系人：{{ item.contact_name }}</p>
                  <p>联系电话：{{ item.contact }}</p>
                  <p>
                    营业时间：{{ item.opening_time }} - {{ item.closing_time }}
                  </p>
                  <p>
                    营业地址：{{
                      item.province == item.city
                        ? `${item.city}市`
                        : `${item.province}省${item.city}市`
                    }}{{ item.fact_location }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </template>
        <a-empty style="margin-top: 200px" v-if="pageData.length === 0" />
      </div>
    </template>
    <template v-else>
      <div class="content">
        <div class="content-warp content-height">
          <div
            class="content content-info"
            v-for="item in pageData"
            :key="item.id"
            @click="handleItemClick(item)"
          >
            <div class="content-text content-info-text">
              <p
                :class="['title', item.id === unfoldItemId ? 'unfoldWrap' : '']"
              >
                {{ item.information }}
              </p>
              <div class="time">
                <div>
                  <span class="address"
                    >地区：{{ item.province }} {{ item.city }}</span
                  >
                  <span>联系方式：{{ handleContact(item) }}</span>
                </div>
                <span>发布时间：{{ item.pub_time }}</span>
              </div>
            </div>
          </div>
          <a-empty style="margin-top: 200px" v-if="pageData.length === 0" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import locationOptions from '@/util/city.json'

export default {
  data() {
    return {
      unfoldItemId: '',
      n: [
        {
          navName: '开料机',
          type: 2,
        },
        {
          navName: '封边机',
          type: 3,
        },
        {
          navName: '侧孔机',
          type: 4,
        },
        {
          navName: '五六面钻',
          type: 5,
        },
        {
          navName: '其它',
          type: 1,
        },
      ],
      typeOptions: [
        {
          value: '',
          label: '全部',
        },
        {
          value: '雕刻机',
          label: '雕刻机',
        },
        {
          value: '电子锯',
          label: '电子锯',
        },
        {
          value: '一体机',
          label: '一体机',
        },
        {
          value: '侧孔机',
          label: '侧孔机',
        },
        {
          value: '五/六面钻',
          label: '五/六面钻',
        },
        {
          value: '推台锯',
          label: '推台锯',
        },
        {
          value: '封边机',
          label: '封边机',
        },
        {
          value: '其他设备',
          label: '其他设备',
        },
      ],
      locationOptions,
      fieldNames: { label: 'label', value: 'label', children: 'children' },
    }
  },
  props: {
    // 活跃的父级导航，对应活跃的页面
    activeNav: {
      type: String,
      default: '',
    },
    // 数据
    pageData: {
      type: Array,
      default: () => [],
    },
    isShowNav: {
      type: Boolean,
      default: false,
    },
    activeChildrenIndex: {
      type: Number,
      default: 1,
    },
    searchData: {
      type: Object,
      default: {},
    },
  },
  watch: {
    //  监听父级导航栏，改变activeChildrenIndex的状态
    activeNav: {
      handler(n) {
        let v
        if (n === 'equipList') {
          v = 2
        } else {
          v = undefined
        }
        this.$emit('update:activeChildrenIndex', v)
      },
      immediate: true,
    },
  },
  methods: {
    handleNav(idx) {
      this.activeIndex = idx
      this.$emit('update:activeChildrenIndex', idx)
      this.$emit('equipListChange', idx)
    },
    handleItemClick(item) {
      if (item.id === this.unfoldItemId) {
        this.unfoldItemId = ''
      } else {
        this.unfoldItemId = item.id
      }
    },
    handleContact(item) {
      if (!item.contact) return ''
      if (this.unfoldItemId === item.id) {
        return item.contact
      } else {
        const str = item.contact + ''
        let len = str.length
        if (len <= 3) return item.contact
        return `${str.substring(0, 3)}${'*'.repeat(len - 3)}`
      }
    },
    handleSearchData() {
      this.handleNav()
    },
  },
}
</script>

<style lang="less" scoped>
#equi-comps-page {
  .nav {
    ul {
      display: flex;
      height: 48px;
      margin: 0;
      .nav-item {
        height: 100%;
        line-height: 48px;
        list-style: none;
        margin: 0 16px;
        &.active-nav {
          border-bottom: 2px solid #6476ff;
        }
        &:hover {
          color: #6476ff;
          cursor: pointer;
        }
      }
    }
  }
  .content-warp {
    //  减去其他区域的高度 计算内容区域的高度
    height: calc(100vh - 226px);
    overflow-y: auto;
    margin-top: 32px;
    .topContent {
      padding: 20px 0 10px;
      border-bottom: 1px solid #d9d9d9;
      input {
        width: 73%;
      }
    }
    .content {
      display: flex;
      min-height: 107px;
      width: 100%;
      margin-top: 32px;
      &:first-child {
        margin-top: 0;
      }
      .content-img {
        height: 153px;
        width: 153px;
        object-fit: cover;
      }
      .content-text {
        flex: 1;
        color: #999999;
        padding-left: 16px;
        .title {
          font-weight: 600;
          font-size: 18px;
          color: #333;
          margin-bottom: 30px;
          width: 935px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;
        }
        .address {
          margin-bottom: 8px;
        }
        .time {
          display: flex;
          justify-content: space-between;
        }
      }
      .content-info-text {
        .title {
          margin-bottom: 16px;
        }
        .time .address {
          margin-right: 80px;
        }
      }
    }
    .mainContent {
      flex-wrap: wrap;
      justify-content: space-around;
      .pageItem {
        width: 460px;
        flex-shrink: 0;
        border: 1px solid #d9d9d9;
        padding: 10px;
        box-sizing: border-box;
        margin-top: 30px;
        .pageDataImg {
          max-width: 130px;
        }
      }
    }

    .content-info {
      min-height: 83px !important;
    }
  }
  // 用于切换内容高度
  .content-height {
    //没有导航的区域需要再减去导航
    height: calc(100vh - 178px) !important;
  }
  .unfoldWrap {
    width: 100% !important;
    white-space: normal !important;
    color: ForestGreen !important;
  }
}
</style>
