<template>
  <div class="equip-environment">
    <div class="top-nav">
      <div class="nav-wrap">
        <span
          v-for="item in navList"
          :key="item.type"
          :class="['top-nav-item', activeNav == item.type ? 'active-nav' : '']"
          @click="changeNav(item)"
          >{{ item.name }}</span
        >
      </div>
    </div>
    <div class="wrap" v-loading="pageLoading">
      <div>
        <div class="content">
          <equi-comps-page
            :activeNav="activeNav"
            :activeChildrenIndex.sync="activeChildrenIndex"
            :pageData="pageData"
            :searchData="searchData"
            @equipListChange="equipListChange"
          >
          </equi-comps-page>
        </div>
        <div class="footer">
          <el-button class="footer-btn" @click="openDialog">{{
            dialogBtnOrTitleName
          }}</el-button>
          <el-pagination
            :page-size="pageSize"
            :current-page.sync="currentPage"
            :pager-count="9"
            layout="prev, pager, next"
            :total="pageCount"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <page-modal
      :modalFormConfig="modalConfig"
      :title="dialogBtnOrTitleName"
      ref="modal"
      @modalClickConfirm="modalClickConfirm"
      :loading="isLoading"
      :activeNav="activeNav"
    />
  </div>
</template>

<script>
import pageModal from '@/components/modal'
import { mapState } from 'vuex'

import equiCompsPage from './children/equiCompsPage.vue'
import {
  demandInfoModalConfig,
  equipListModalConfig,
  equipmentMaintenanceModalConfig,
  supplierListModalConfig,
} from './modalConfig'

export default {
  data() {
    return {
      navList: [
        { name: '设备列表', type: 'equipList' },
        { name: '求购信息', type: 'demandInfo' },
        { name: '供应商列表', type: 'supplierList' },
        { name: '设备维修', type: 'equipmentMaintenance' },
      ],
      // 激活的导航
      activeNav: 'equipList',
      // 当前页/每页数量
      currentPage: 1,
      pageSize: 6,
      // 设备列表页面的nav
      activeChildrenIndex: 0,
      // 模态框内表单配置
      equipListModalConfig,
      demandInfoModalConfig,
      supplierListModalConfig,
      equipmentMaintenanceModalConfig,
      searchData: {
        location: [],
        type: undefined,
        customType: '',
      },
    }
  },
  computed: {
    ...mapState('equipEnvironment', ['isLoading', 'pageLoading']),
    dialogBtnOrTitleName() {
      let btnName = ''
      switch (this.activeNav) {
        case 'supplierList':
          btnName = '我要合作'
          break
        case 'equipmentMaintenance':
          btnName = '我要入驻'
          break
        default:
          btnName = '我要发布'
          break
      }
      return btnName
    },
    modalConfig() {
      return this[`${this.activeNav}ModalConfig`]
    },
    // 根据当前导航的type获取对应数据/总数
    pageData() {
      return this.$store.getters['equipEnvironment/pageListData'](
        this.activeNav
      )
    },
    pageCount() {
      return this.$store.getters['equipEnvironment/pageListCount'](
        this.activeNav
      )
    },
  },
  watch: {
    currentPage(n) {
      this.getPageData()
    },
  },
  methods: {
    changeNav(item) {
      this.activeNav = item.type
      if (item.type !== 'equipList') {
        this.activeChildrenIndex = undefined
      } else {
        this.activeChildrenIndex = 1
      }
      if (this.currentPage !== 1) {
        this.currentPage = 1
      } else {
        this.getPageData()
      }
    },
    // 打开用户提交窗口
    openDialog() {
      this.$refs['modal'].openDialog()
    },
    // 子导航变化
    equipListChange(v) {
      this.activeChildrenIndex = v
      if (this.currentPage !== 1) {
        this.currentPage = 1
      } else {
        this.getPageData()
      }
    },
    // 页数/导航/子导航变化触发请求
    getPageData() {
      let pl = {
        pub_type: this.activeNav,
        page: this.currentPage,
        limit: this.pageSize,
      }
      if (Number.isInteger(this.activeChildrenIndex)) {
        let t = this.activeChildrenIndex
        pl = { ...pl, device_type: t }
      }
      const equipmentType = this.activeNav == 'equipmentMaintenance' ? this.searchData.type : ''
      pl = {
        ...pl,
        province: this.searchData.location[0],
        city: this.searchData.location[1],
        equipment_type: equipmentType,
        filter_word: this.searchData.customType,
      }
      this.$store.dispatch('equipEnvironment/getEquipEnvironmentData', pl)
    },
    // 点击确认提交用户输入信息
    modalClickConfirm(e) {
      // 需要当前modal实例，用于请求完成关闭窗口
      let arg = {
        ...e,
        pub_type: this.activeNav,
        modalElInstance: this.$refs['modal'],
      }
      this.$store.dispatch('equipEnvironment/commitUserInput', arg)
    },
  },
  mounted() {
    this.getPageData()
  },
  components: { pageModal, equiCompsPage },
}
</script>

<style scoped lang="less">
.equip-environment {
  height: 100vh;
  background: #f4f4f4;
  .top-nav {
    height: 60px;
    background: #000;
    .nav-wrap {
      width: 1200px;
      display: flex;
      margin: 0 auto;
    }
    .top-nav-item {
      color: #fff;
      height: 60px;
      width: 96px;
      line-height: 60px;
      cursor: pointer;
      text-align: center;
      &.active-nav {
        color: #484848;
        background: #fff;
      }
      &:hover {
        color: #484848;
        background: #fff;
      }
    }
  }
  .wrap {
    width: 1200px;
    margin: 0 auto;
    padding: 0 32px;
    background: #fff;
    .footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 86px;
      .footer-btn {
        width: 112px;
        height: 40px;
        color: #fff;
        background-color: #18afc8;
      }
      .el-pagination {
        /deep/ .active {
          color: #18afc8;
          // border: 1px solid #18AFC8;
        }
      }
    }
  }
}
</style>
