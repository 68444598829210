<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="500px"
      destroy-on-close
      @close="beforeCloseDialog"
      :top="activeNav == 'equipmentMaintenance' ? '11vh' : '15vh'"
    >
      <!-- 当对话框不存在时销毁form表单 -->
      <base-form
        v-bind="modalFormConfig"
        :formData.sync="formData"
        ref="modalForm"
        v-if="dialogVisible"
      ></base-form>
      <span slot="title" class="title">{{ title }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="dialogVisible = false"
          size="small"
          :style="{ ...btnBaseStyle, ...cancelBtnStyle }"
        >
          取 消
        </el-button>
        <el-button
          type="primary"
          @click="handleConfirm"
          size="small"
          :style="{ ...btnBaseStyle, ...confirmBtnStyle }"
          :loading="loading"
        >
          {{
            loading
              ? '正在处理中,请勿关闭弹窗...'
              : activeNav == 'equipmentMaintenance'
              ? '提交申请'
              : '确 定'
          }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import baseForm from '@/baseui/from/index'

export default {
  data() {
    return {
      formData: {},
      dialogVisible: false,
    }
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    // 配置信息对象
    modalFormConfig: {
      type: Object,
      default: () => {},
      required: true,
    },
    // 按钮公用样式
    btnBaseStyle: {
      type: Object,
      default: () => {},
    },
    // 确认/取消按钮单独样式
    confirmBtnStyle: {
      type: Object,
      default: () => ({
        backgroundColor: '#18A8C7',
      }),
    },
    cancelBtnStyle: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    activeNav: {
      type: String,
      default: '',
    },
  },
  components: { baseForm },
  computed: {
    fields() {
      let d = []
      for (const v of this.modalFormConfig.formItems) {
        d.push(v.field)
      }
      return d
    },
  },
  methods: {
    handleConfirm() {
      this.$refs['modalForm'].validate((valid) => {
        if (!valid) return
        if (
          this.formData.equipment_repair?.length == 1 &&
          this.formData.equipment_repair?.includes('其他设备') &&
          !this.formData.other_equipment
        ) {
          this.$message({
            message: '请输入其他设备',
            type: 'error',
            duration: 2000,
          })
          return
        }
        const data = { ...this.formData }
        for (const key of this.fields) {
          if (!(key in data)) {
            data[key] = ''
          }
        }
        this.$emit('modalClickConfirm', this.formData)
      })
    },
    openDialog() {
      this.dialogVisible = true
    },
    // 由外部可调用的关闭
    closeDialog() {
      this.dialogVisible = false
    },
    // 关闭dialog的回调  清空数据
    beforeCloseDialog() {
      this.formData = {}
    },
  },
}
</script>

<style lang="less" scoped>
.el-dialog {
  .title {
    font-weight: 600;
    font-size: 16px;
  }
}
/deep/ .Itemequipment_repair {
  margin-bottom: 10px !important;
  .el-form-item__label {
    width: 110px !important;
  }
  .el-form-item__content {
    width: 335px;
  }
  .ant-checkbox-wrapper {
    margin-left: 8px !important;
  }
  form .ant-mentions,
  form textarea.ant-input {
    margin: 0 !important;
  }
  p {
    margin: 0 !important;
    margin-top: 5px !important;
  }
}
</style>
